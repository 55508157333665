<template>
    <CModalExtended
        color="dark"
        :closeOnBackdrop="false"
        :title="$t('label.DirectDischarge')"
        :show.sync="$store.state.planificacionestiba.modalMovimientoDirecto"
    >   
        <div class="container-fluid">
            <CRow>
                <CCol sm="12">
                    <CSelect
                        :label="$t('label.movementType')"
                        :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                        disabled
                        v-uppercase
                        :options="[]"
                        v-model="formMovimientoDirect.TransacStowageId"
                        required 
                    >
                    </CSelect>
                </CCol>
                <CCol sm="12">
                    <CInput
                    label="CONTAINER CODE"
                    addLabelClasses="required"
                    v-uppercase
                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                    v-model="$v.formMovimientoDirect.SiglaContenedor.$model"
                     @blur="$v.formMovimientoDirect.SiglaContenedor.$touch()"
                    :addInputClasses="{ 'is-invalid': $v.formMovimientoDirect.SiglaContenedor.$error }"
                    required 
                    >
                        <template #invalid-feedback>
                            <div v-if="$v.formMovimientoDirect.SiglaContenedor.$error">
                                <div class='text-danger' v-if="!$v.formMovimientoDirect.SiglaContenedor.required">{{$t('label.required')}}</div>
                            </div>
                        </template>
                    </CInput>
                </CCol>
                <CCol sm="12">
                    <CInput
                    :label="$t('label.originPort')"
                    addLabelClasses="required"
                    v-uppercase
                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                    v-model="$v.formMovimientoDirect.PuertoProcedencia.$model"
                     @blur="$v.formMovimientoDirect.PuertoProcedencia.$touch()"
                    :addInputClasses="{ 'is-invalid': $v.formMovimientoDirect.PuertoProcedencia.$error }"
                    required 
                    >
                        <template #invalid-feedback>
                            <div v-if="$v.formMovimientoDirect.PuertoProcedencia.$error">
                                <div class='text-danger' v-if="!$v.formMovimientoDirect.PuertoProcedencia.required">{{$t('label.required')}}</div>
                            </div>
                        </template>
                    </CInput>
                </CCol>
                <CCol sm="12">
                    <CInput
                    label="BL NO"
                    v-uppercase
                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                    v-model="$v.formMovimientoDirect.Bl.$model"
                     @Blur="$v.formMovimientoDirect.Bl.$touch()"
                    :addInputClasses="{ 'is-invalid': $v.formMovimientoDirect.Bl.$error }"
                    disabled
                    required 
                    >
                        <template #invalid-feedback>
                            <div v-if="$v.formMovimientoDirect.Bl.$error">
                                <div class='text-danger' v-if="!$v.formMovimientoDirect.Bl.required">{{$t('label.required')}}</div>
                            </div>
                        </template>
                    </CInput>
                </CCol>
                <CCol sm="12">
                    <CInput
                    label="CONSIGNEE"
                    addLabelClasses="required"
                    v-uppercase
                    :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                    
                    v-model="$v.formMovimientoDirect.Consignario.$model"
                    @Blur="$v.formMovimientoDirect.Consignario.$touch()"
                    :addInputClasses="{ 'is-invalid': $v.formMovimientoDirect.Consignario.$error }"
                    required 
                    >
                        <template #invalid-feedback>
                            <div v-if="$v.formMovimientoDirect.Consignario.$error">
                                <div class='text-danger' v-if="!$v.formMovimientoDirect.Consignario.required">{{$t('label.required')}}</div>
                            </div>
                        </template>
                    </CInput>
                </CCol>
                <CCol sm="12">
                    <CTextarea
                        :label="$t('label.MOVIMIENTO.observation')"
                        v-uppercase
                        v-model="$v.formMovimientoDirect.MovStowageReasonDs.$model"
                        :addInputClasses="{ 'is-invalid': $v.formMovimientoDirect.MovStowageReasonDs.$error }"
                        :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                        required
                        :plaintext="false"
                        :readonly="false"
                        :lazy="(true,500)"
                    >
                        <template #invalid-feedback>
                            <div v-if="$v.formMovimientoDirect.MovStowageReasonDs.$error">
                            </div>
                        </template>
                    </CTextarea>
                </CCol>
            </CRow>
        </div>
        <div slot="footer">
            <CButton
                color="add"
                class="m-2"
                @click="registerData"
                :disabled="apiStateLoading"
            >
                <div v-if="apiStateLoading">
                    <span  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                    {{$t('button.accept')}}
                </div>

                <div v-if="!apiStateLoading">
                    <CIcon name="checkAlt"/>&nbsp;
                    {{$t('button.accept')}}
                </div>
            </CButton>
            <CButton
                color="wipe"
                @click="cerrarModal"
                class="m-2"
                :disabled="apiStateLoading"
            >
                <CIcon name="x"/>&nbsp;
                {{$t('button.cancel')}}
            </CButton>
        </div>
    </CModalExtended>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import  { FormMovimientoDirect }  from '@/_validations/planificacion-estiba/containerdataValidations';

    //data
    function data() {
        return {
            formMovimientoDirect: {
                TransacStowageId:null,
                MovStowageReasonDs:'',
                SiglaContenedor:'',
                PuertoProcedencia:'',
                Bl:'',
                Consignario:''
            }
        }
    }
    //methods
    function registerData(){
        this.$v.formMovimientoDirect.$touch();
        if (this.$v.formMovimientoDirect.$pending || this.$v.formMovimientoDirect.$error) return;
        this.$store.state.planificacionestiba.modalMovimientoDirecto = false;
    }
    function cerrarModal(){
        if(this.apiStateLoading)
            return
        this.$store.state.planificacionestiba.modalMovimientoDirecto = false;
    }
    //computeds
    function apiStateLoading() {
        return this.apiState === ENUM.LOADING;
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING || this.apiStateForm === ENUM.INIT){
            carga = !carga;
        }
        return carga;
    }
    //watch
    function modalMovimientoDirecto(newQuestion){
        if(newQuestion === false){
            this.formMovimientoDirect.TransacStowageId = null;
            this.formMovimientoDirect.MovStowageReasonDs = ''; 
            this.formMovimientoDirect.SiglaContenedor = ''; 
            this.formMovimientoDirect.PuertoProcedencia = ''; 
            this.formMovimientoDirect.Bl = ''; 
            this.formMovimientoDirect.Consignario = ''; 
            this.$v.$reset();
        }else{

        }
    }
    export default {
        name:'modal-movimiento-descarga',
        data,
        methods:{
            registerData,
            cerrarModal,
        },
        props: {
            containerList:{
                type: Array,
                required: true,
                default: () => [],
            },
        },
        validations(){ return FormMovimientoDirect() },
        directives: {
            uppercase: {
                bind(el, _, vnode) {
                el.addEventListener('input', (e) => {
                    e.target.value = e.target.value.toUpperCase()
                    vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
                });
                }
            }
        },
        computed:{
            apiStateLoading,
            apiStateFormLoading,
            ...mapState({
                modalMovimientoDirecto: (state) => state.planificacionestiba.modalMovimientoDirecto,
                apiState: (state) => state.planificacionestiba.apiState,
                apiStateForm: (state) => state.planificacionestiba.apiStateForm,
                EdiFileId: (state) => state.planificacionestiba.EdiFileId,
                EdiFileInfo: (state) => state.planificacionestiba.EdiFileInfo,
                planificacionId: state => state.planificacionestiba.planificacionId,
            })
        },
        watch: {
            modalMovimientoDirecto,
        }
    }
</script>